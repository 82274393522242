
<template>
  <div style="background-color: #fff;">
    <div class="banshi">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div v-for="item in swiperList" :key="item.id" class="swiper-slide"
                    :style="`background-image:url(${item.imgUrl})`">
                    <!--<h3>{{ item.title }}</h3>-->
                </div>
            </div>
            <!-- 指示器 -->
            <!--<div class="swiper-pagination"></div>-->
            <!-- 左右按钮 -->
            <!--<div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>-->
        </div>
    </div>
</div>
</template>
<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入
export default {
    name: "vinit",
    components: {},
    data() {
        return {
            swiper: null,
            swiperList: [{
                id: 1,
                title: '',
                imgUrl: require("@/assets/home/Exorcism.png"),
            }, {
                id: 2,
                title: '',
                imgUrl: require("@/assets/home/Exorcism.png"),
            }, {
                id: 3,
                title: '',
                imgUrl: require("@/assets/home/Exorcism.png"),
            }, {
                id: 4,
                title: '',
                imgUrl: require("@/assets/home/Exorcism.png"),
            }, {
                id: 5,
                title: '',
                imgUrl: require("@/assets/home/Exorcism.png"),
            }, {
                id: 6,
                title: '',
                imgUrl: require("@/assets/home/Exorcism.png"),
            }, {
                id: 7,
                title: '',
                imgUrl: require("@/assets/home/Exorcism.png"),
            }]
        }
    },
    methods: {
        getSwiper() {
            this.swiper = new Swiper(".swiper-container", {
                loop: true, // 无缝
                autoplay: { //自动开始
                    delay: 1800, //时间间隔
                    disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                },
                paginationClickable: true,
                slidesPerView: 5, // 一组三个
                spaceBetween: 10, // 间隔
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                // 窗口变化,重新init,针对F11全屏和放大缩小,必须加
                observer: true,
                observeParents: true,
                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true, // 分页器可以点击
                },
            })
        }

    },
    mounted() {
        this.getSwiper()
    },
}
</script>
<style lang="scss" scoped>
.banshi {
        width: 96%;
        margin-left: 2%;
        padding: 70px 0;
        .swiper-slide {
            position: relative;
            height: 484px;
            width: 361px;
            //@debugpadding: 36px 22px;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: #F7F8FA;
        }
    }
</style>>

